<template>
  <div
    class="modal absolute bottom-0 w-screen h-screen z-50 flex flex-col justify-end items-stretch pointer-events-none max-w-md sm:mb-3 mr-3"
  >
    <!-- :class="{ 'bg-opaque': active }" -->
    <transition name="fade">
      <div
        class="sm:hidden bg-opaque flex-grow pointer-events-auto"
        @click="send('ABOUT')"
        v-show="context.showAbout"
      ></div>
    </transition>
    <div
      class="splash overflow-auto relative bg-white border border-gray-200 transition duration-300 pointer-events-auto border-2 border-gray-800 border-opacity-25 padding-box bg-white -mt-px rounded shadow-xl"
      :class="{ 'p-0': !context.showAbout, 'pt-4 pb-5 px-6 pr-6': context.showAbout }"
    >
      <div
        class="flex items-baseline justify-between mb-1 cursor-pointer ml-6"
        :class="{ 'pt-4 pb-5 px-6 pr-6': !context.showAbout }"
        @click="send('ABOUT')"
      >
        <img
          class="flex-shrink sm:max-w-3xl w-12 w-64"
          src="../assets/lb_västsvenska_logga_enskild_4.2020v4.svg"
          alt="Litteraturkartan"
        />
        <div class="flex items-center justify-center">
          <font-awesome-icon
            class="text-2xl block color-reddish"
            :class="{ 'transform rotate-180': context.showAbout }"
            icon="chevron-up"
          />
        </div>
      </div>
      <TransitionExpand>
        <div
          v-show="context.showAbout"
          ref="scrollContainer"
          class="text-gray-700 transition-all duration-200"
        >
          <!-- :class="{ 'mt-4 mb-8': active }" -->
          <div class="p-8 font-sans">
            <p class="text-lg font-bold">
              VÄLKOMMEN till Litteraturkartan.se! Här kan du gå på upptäcktsfärd i litteraturens
              tecken genom Bohuslän, Dalsland, Västergötland, Halland, Blekinge och Värmland – och
              genom seklerna. Kända och okända personer, mer eller mindre bortglömda platser, och
              texter som speglar och avtäcker landskapet blir dina färdkamrater. Gå vilse, och hitta
              rätt! Res dig lycklig – i både tanke och handling.
            </p>

            <br />

            <p class="">
              Klicka på hjälp-knappen märkt med ett <span class="font-bold font-serif">i</span>
              för en förklaring av kartans funktioner, eller klicka
              <a class="" @click="send('HELP')">här</a>.
            </p>

            <p class="">
              Litteraturkartan har tagits fram av
              <a href="https://litteraturbanken.se">Litteraturbanken.se</a> i samarbete med Johan
              Roxendal på <a href="https://spraakbanken.gu.se">Språkbanken Text</a>. Vid Göteborgs
              universitet medverkade
              <a href="https://gu.se/digital-humaniora">Centrum för Digital humaniora</a> och Johan
              Åhlfeldt i ett tidigt skede.
              <a href="https://www.vgregion.se/f/kulturutveckling/"
                >Förvaltningen för kulturutveckling</a
              >
              har varit delaktiga i projektet, vilket finansierats med stöd från Västra
              Götalands-regionen.
            </p>

            <!-- <p>Gå till <a href="https://litteraturbanken.se">Litteraturbanken.se</a></p> -->

            <p>
              Frågor, kommentarer eller förslag? <br />
              Maila
              <a href="mailto:info@litteraturbanken.se?subject=Litteraturkartan"
                >info@litteraturbanken.se</a
              >
            </p>

            <h2 class="text-lg mt-10 custom-sans uppercase font-black leading-tight mb-4">
              Mer från Litteraturbanken
            </h2>

            <ul>
              <li class="flex">
                <span class="text-red-600 mr-2 relative top-1">* </span>
                <a class="hover_link duration-100" href="https://litteraturbanken.se/bibliotek"
                  >Botanisera i biblioteket</a
                >
              </li>
              <li class="flex">
                <span class="text-red-600 mr-2 relative top-1">* </span>
                <a class="hover_link duration-100" href="https://litteraturbanken.se/epub"
                  >Ladda ner epub</a
                >
              </li>
              <li class="flex">
                <span class="text-red-600 mr-2 relative top-1">* </span>
                <a class="hover_link duration-100" href="https://litteraturbanken.se/ljudochbild"
                  >Ljud och bild</a
                >
              </li>
              <li class="flex">
                <span class="text-red-600 mr-2 relative top-1">* </span>
                <a class="hover_link duration-100" href="https://litteraturbanken.se/diktensmuseum"
                  >Diktens museum</a
                >
              </li>
              <li class="flex">
                <span class="text-red-600 mr-2 relative top-1">* </span>
                <a
                  class="hover_link duration-100"
                  href="https://litteraturbanken.se/översättarlexikon"
                  >Svenskt översättarlexikon</a
                >
              </li>
              <li class="flex">
                <span class="text-red-600 mr-2 relative top-1">* </span>
                <a class="hover_link duration-100" href="https://litteraturbanken.se/dramawebben"
                  >Dramawebben</a
                >
              </li>
              <li class="flex">
                <span class="text-red-600 mr-2 relative top-1">* </span>
                <a class="hover_link duration-100" href="https://litteraturbanken.se/skolan"
                  >Litteraturbankens skola</a
                >
              </li>
            </ul>

            <h2
              id="dokumentation"
              ref="docs"
              class="text-lg mt-10 custom-sans uppercase font-black leading-tight mb-4"
            >
              Dokumentation
            </h2>

            <figure>
              <video src="digikult_litteraturkartan.mp4" controls>
                <track label="Swedish" kind="subtitles" srclang="sv" src="captions.vtt" default />
              </video>
              <figcaption class="italic text-gray-500">En teknisk översikt över kartan.</figcaption>
            </figure>
          </div>
        </div>
      </TransitionExpand>
    </div>
  </div>
</template>

<script>
import TransitionExpand from "./TransitionExpand"
import { deparam } from "../util"
export default {
  name: "Modal",
  components: {
    TransitionExpand
  },
  data() {
    return {
      active: false
    }
  },
  mounted() {
    if (deparam().om && !this.context.showAbout) {
      if (window.location.hash == "#dokumentation") {
        setTimeout(() => this.$refs.docs.scrollIntoView({ behavior: "smooth" }), 1000)
      }
      this.send("ABOUT")
    }
  },
  methods: {}
}
</script>

<style lang="postcss" scoped>
::cue {
  font-size: 70%;
}
.initial {
  float: left;
  line-height: 0.9;
  font-size: 5rem;
}
.splash {
  max-height: 88%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.shadow-top {
  box-shadow: #999 -4px 1px 5px;
}
.bg-opaque {
  background-color: #0a0c10b3;
}

.text-indent-0 {
  text-indent: 0;
}

.hover_link {
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: all 300ms;
  &:hover {
    text-decoration-color: rgba(248, 113, 113);
    @apply text-red-400;
  }
}
p a {
  @apply text-red-400 hover:text-red-700;
  text-decoration: underline;
}

p + p {
  text-indent: 0;
}
p {
  margin-bottom: 1rem;
}

@screen sm {
  .modal {
    margin-left: 3.6rem;
  }
}
</style>
