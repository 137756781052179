<template>
  <div class="">
    <img class="m-auto" :class="{ 'rounded-t-lg': i == 0 }" :src="article.imgDataUrl" alt="" />
    <div class="px-8 pt-8 pb-4">
      <h2 class="text-2xl custom-sans uppercase font-black leading-tight mb-8">
        {{ article.header }}
      </h2>
      <!-- <span
                class="absolute top-0"
                v-html="
                  article.free_text
                    .split('.')
                    .slice(0, 2)
                    .join('.') + '. '
                "
              ></span> -->
      <div
        class="paragraph font-serif text-lg transition-all duration-200 overflow-hidden"
        v-html="article.free_text"
        :class="{
          'line-clamp': !article.shortP && !article.more,
          'line-clamp-short': article.shortP && !article.more
        }"
      ></div>
      <div class="mt-4" v-show="article.more && article.article_author">
        <span
          class="lowercase small-caps inline-block mr-2 text-gray-500"
          v-if="article.article_author_prefix"
          >{{ article.article_author_prefix }}</span
        >
        <em>{{ article.article_author }}</em>
      </div>

      <a
        class="bg-yellow-200 cursor-pointer font-sans hover:text-yellow-600 inline-block mt-2 mt-4 p-2 rounded-lg font-bold text-sm text-yellow-900 uppercase"
        v-show="!article.more"
        @click="$emit('onMore', article)"
        >Läs mer</a
      >

      <a
        class="bg-yellow-200 cursor-pointer font-sans hover:text-gray-600 inline-block mt-2 mt-4 p-2 rounded-lg font-bold text-sm text-yellow-900 uppercase"
        v-show="article.more"
        @click="$emit('onMore', article)"
        >Läs mindre</a
      >

      <a
        v-if="$isDev"
        class="bg-indigo-400 cursor-pointer font-sans hover:text-indigo-600 inline-block mt-2 mt-4 p-2 rounded-lg font-bold text-sm text-indigo-900 uppercase ml-2 mr-2"
        @click="onEditClick(article)"
        >Redigera</a
      >

      <ul class="mt-4 mb-4" v-if="article.travel">
        <li v-if="!article.travel.isEnd">
          <a class="text-red-800 hover:text-red-600" :href="urlFromTravel(article.travel.next)">
            Följ {{ article.travel.travelName }}</a
          >.
        </li>
        <li v-if="!article.travel.isStart">
          <a class="text-red-800 hover:text-red-600" :href="urlFromTravel(article.travel.start)">
            Gå till början av {{ article.travel.travelName }}</a
          >.
        </li>
      </ul>

      <div class="paragraph mt-8 text-sm" v-if="article.more" v-html="article.red_additions"></div>
      <div class="block italic mt-4" v-show="article.more">{{ article.editor }}</div>
    </div>
    <ul
      class="text-md custom-sans text-gray-700 font-bold uppercase p-4 tracking-tight pb-8 sm:pb-4"
    >
      <li class="hover:text-red-800 flex items-center mb-1">
        <span class="w-12 flex-none">
          <font-awesome-icon
            class="text-lg mx-auto block text-gray-700 -mt-1"
            icon="map-marker-alt"
          />
        </span>
        <a
          :href="
            'https://www.google.com/maps/search/?api=1&query=' +
            placeObj.latlong.lat +
            ',' +
            placeObj.latlong.lng
          "
          target="_blank"
          ><span v-if="placeObj.adress"> {{ placeObj.adress }},</span> {{ placeObj.namn }}</a
        >
      </li>
      <li
        class="hover:text-red-800"
        v-if="
          article.authorInfo &&
          (article.authorInfo.full_name || article.author) &&
          getAuthorURL(article)
        "
      >
        <a class="flex items-center mb-1" :href="getAuthorURL(article)" target="_blank">
          <span class="w-12 flex-none">
            <font-awesome-icon
              class="text-lg mx-auto block text-gray-700 -mt-1"
              icon="user"
            /> </span
          >{{ article.authorInfo.full_name || article.author }}</a
        >
      </li>

      <li class="" v-if="article.author && !article.authorInfo.full_name && !getAuthorURL(article)">
        <span class="flex items-center mb-1">
          <span class="w-12 flex-none">
            <font-awesome-icon class="text-lg mx-auto block text-gray-700 -mt-1" icon="user" />
          </span>
          {{ article.author }}
        </span>
      </li>
      <li class="hover:text-red-800 flex items-center mb-1" v-if="article.title && article.lb">
        <span class="w-12 flex-none">
          <font-awesome-icon class="text-lg mx-auto block text-gray-700 -mt-1" icon="book" />
        </span>
        <a :href="article.titleurl" target="_blank"> Läs boken på LB.se </a>
      </li>
      <li class="flex items-center mb-1">
        <span class="w-12 flex-none">
          <font-awesome-icon class="text-lg mx-auto block text-gray-700 -mt-1" icon="share" />
        </span>
        <span class=""
          ><a
            class="hover:text-red-800"
            :href="'https://facebook.com/sharer/sharer.php?u=' + shareableUrlFromDetail(article)"
            target="_blank"
            rel="noopener"
            >Facebook</a
          >
          <span v-if="hasShare">
            eller
            <a class="hover:text-red-800" @click="systemShareDialog(article)">Välj…</a>
          </span>
          <!-- <a
            class="hover:text-red-800"
            :href="'https://twitter.com/intent/tweet?url=' + shareableUrlFromDetail(article)"
            target="_blank"
            rel="noopener"
            >Twitter</a> -->
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { param } from "../../util"
import * as backend from "../../backend"
export default {
  setup() {
    return {}
  },
  props: {
    i: {
      type: Number,
      default: null
    },
    article: {
      type: Object
    },
    placeObj: {
      type: Object
    }
  },
  computed: {
    hasShare() {
      return navigator.share
    }
  },
  methods: {
    async onEditClick(detail) {
      await backend.modifyLastPage(detail.id)
      let win = window.open("/login/admin/#/_/collections/article2/" + detail.id, "_blank")
      if (!win) return
      // window.win = win
      win.onload = function () {
        setTimeout(() => {
          win?.document?.querySelector("button[type=submit]")?.click()
        }, 500)
      }
    },
    urlFromTravel(travel) {
      return param({ article: travel.id, id: travel.placeid })
    },
    systemShareDialog(article) {
      if (navigator.share) {
        navigator
          .share({
            title: "Litteraturkartan",
            text: `Läs om ${article.header} på Litteraturkartan`,
            url: this.shareableUrlFromDetail(article, true)
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing", error))
      }
    },

    shareableUrlFromDetail(detail, pathOnly = false) {
      let { protocol, host, pathname } = window.location
      let path = `${pathname}${param({ article: detail.id, id: detail.placeid.id })}`
      if (pathOnly) return path
      return encodeURIComponent(`${protocol}//${host}${pathOnly}`)
    },
    getAuthorURL(detail) {
      if (detail.authorInfo.authorid) {
        return "https://litteraturbanken.se/författare/" + detail.authorInfo.authorid
      } else {
        return (
          detail.authorInfo.skbl_url ||
          detail.authorInfo.sol_url ||
          detail.authorInfo.sbl_url ||
          detail.authorInfo.wikipedia_url
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
