export function deparam() {
  if (!location.search) return {}
  return Object.fromEntries(
    window.location.search
      .slice(1)
      .split("&")
      .map(str => {
        let [key, val] = str.split("=")
        if (val) return [key, decodeURIComponent(val)]
        else return [key, true]
      })
  )
}

export function param(obj, replace = false) {
  let params = replace ? {} : deparam()
  let encoded = Object.entries({ ...params, ...obj })
    .map(([key, val]) => {
      if (val) return [key, val].join("=")
      else if (val === null) return null
      else return key
    })
    .filter(Boolean)
    .join("&")
  if (!encoded) {
    return process.env.BASE_URL
  } else {
    return "?" + encoded
  }
}

export function isDev() {
  return !["litteraturbanken.se", "red.litteraturbanken.se"].includes(window.location.hostname)
}
