<template>
  <div
    @click="onClickEverything($event)"
    id="app"
    class="font-serif container"
    ref="main"
    @keyup.right="onRight()"
    @keyup.left="onLeft()"
    tabindex="0"
  >
    <!-- <header>
      <img
        v-show="!details"
        class="absolute w-24 right-0 z-10 opacity-75 mt-4"
        src="./assets/lb_monogram_black_black.svg"
        alt="Litteraturbankens logotyp"
      />
    </header> -->

    <transition name="fade">
      <Search
        v-show="!isZooming && !context.placeId"
        @select="
          send({ type: 'DETAILS', placeid: $event.placeid, articleid: $event.articleid, zoom: 14 })
        "
      ></Search>
    </transition>

    <Map :details="this.context.placeId" @mapclick="onMapClick($event)"></Map>

    <!-- add preview image above right -->

    <!-- <transition name="details"> -->
    <Details v-show="!isZooming" @load="onDetailsLoad($event)" @close="onEsc()"></Details>
    <!-- </transition> -->

    <transition name="slide">
      <Modal
        class="transition-all duration-300"
        :class="{ under: context.placeId || context.libraryId || context.showHelp }"
      ></Modal>
      <!-- <div
        class="absolute splash top-0 z-50 h-screen w-screen flex items-center justify-center bg-white"
        v-html="require(`./assets/lb_västsvenska_logga_enskild_4.2020.svg`)"
      ></div> -->
    </transition>
  </div>
</template>

<script>
import Map from "./components/Map.vue"

import Details from "./components/details/Details.vue"
import Search from "./components/Search.vue"
import Modal from "./components/Modal.vue"

import { getAllPlaces } from "./backend"
// import EventBus from "./eventbus"
// import useMachine from "./statemachine"

// sm.onTransition(state => console.log("app onTransition", state))

import { param, deparam } from "./util"

export default {
  name: "app",
  components: {
    Map,
    Details,
    Modal,
    Search
  },
  setup() {
    // const { state, send, service } = useMachine()
    // service.onTransition(state => console.log("state", state))
    // // service.start()
    // return {
    //   state,
    //   send,
    //   service
    // }
  },
  data() {
    return {
      show: "",
      loading: true,
      isZooming: false
    }
  },
  async created() {
    // let data = await backend.getAllPlaces()

    window.onpopstate = async ({ state }) => {
      console.log("onpopstate", state)
      if (state && state.place) {
        this.send({ type: "DETAILS", placeid: state.place })
      } else {
        this.send({ type: "ESC" })
      }
    }
  },
  async mounted() {
    await getAllPlaces()
    let { id, article, library } = deparam()
    console.log("id, article", id, article, library)
    if (id) {
      this.send({
        type: "DETAILS",
        placeid: Number(id),
        articleid: Number(article),
        zoom: 14,
        isInit: true
      })
    } else if (library) {
      this.send({
        type: "DETAILS",
        libraryid: library,
        zoom: 14,
        isInit: true
      })
    }
    document.addEventListener("keyup", (event) => {
      if (event.which == 27) {
        // esc
        this.onEsc()
      }
    })
  },
  watch: {},
  methods: {
    onMapClick(event) {
      // this.onEsc()
      this.send({ type: "MAP_CLICK" })
    },
    onClickEverything($event) {
      let target = $event.target
      let href = target.getAttribute("href")
      // $event.preventDefault()
      if (href?.match(/^\/?\?id=/)) {
        let params = Object.fromEntries(new URLSearchParams(href.split("?").slice(-1)[0]))
        this.send({
          type: "DETAILS",
          placeid: Number(params.id),
          articleid: Number(params.article),
          zoom: 15
        })
        $event.preventDefault()
      }
    },
    async onDetailsLoad([details]) {},
    onRight() {
      if (!this.context.placeid) return
      this.send({
        type: "DETAILS",
        placeid: Number(this.context.placeid + 1),
        articleid: Number(params.article),
        zoom: 15
      })
    },
    onLeft() {
      if (!this.context.placeid) return
      this.setPlace(this.context.placeid - 1)
    },
    onEsc() {
      console.log("onEsc")
      this.$refs.main.focus()
      this.send("ESC")
    }
  }
}
</script>

<style src="leaflet/dist/leaflet.css"></style>
<style src="./assets/tailwind.css"></style>

<style lang="scss">
html {
  @apply bg-gray-900;
}
.audio-container {
  text-indent: 0;
  margin: 1rem 0;
}
.custom-sans {
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

p + p {
  text-indent: 1.2em;
}
.paragraph a {
  @apply text-red-800;
  &:hover {
    @apply text-red-600;
  }
}
a {
  @apply cursor-pointer transition-colors duration-200;
}
// a:hover {
//   @apply text-gray-900;
// }

.leaflet-bar.leaflet-control-zoom {
  @apply shadow-lg;
}

.my-div-icon {
  background-color: red;
  width: 100px;
  height: 100px;
}
#app {
}

.leaflet-container {
}
.leaflet-popup-content {
  font: inherit;
  font-size: 1.3rem;
}
.leaflet-marker-icon:not(.leaflet-labeled-icon),
.leaflet-marker-shadow {
  margin-top: -40px;
  margin-left: -12px;
}

.leaflet-pane.leaflet-map-pane,
.leaflet-pane.leaflet-tile-pane {
  bottom: 0;
  right: 0;
}
.leaflet-popup-content {
  font-size: 1.1em;
}
.leaflet-popup-content-wrapper {
  border-radius: 6px;
}
.leaflet-tooltip-pane {
  font-size: 1em;
  font-family: inherit;
}
img.leaflet-tile {
  // filter: saturate(0.3);
}

// .leaflet-map-pane:before {
//   position: absolute;
//   top: -7000px;
//   bottom: -7000px;
//   left: -7000px;
//   right: -7000px;
//   background-color: black;
//   content: "";
//   display: block;
//   z-index: 390;
//   mix-blend-mode: hue;
//   opacity: 0.5;
// }

.line-clamp > :first-child {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp > :not(:first-child) {
  display: none;
}

.line-clamp-short {
  & > :not(:first-child, :nth-child(2)) {
    display: none;
  }
  & > :nth-child(3) {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.paragraph blockquote {
  margin: 1em 0 2em 0;
  font-size: 0.8em;
  padding-left: 0.8em;
}

.slide-enter-active,
.slide-leave-active {
  transition: bottom 300ms ease-in-out;
}

.slide-enter,
.slide-leave-to {
  bottom: -4.8rem;
}
.under {
  bottom: -7rem !important;
}

.leaflet-marker-icon.icon-library {
  top: 5px;
}

.leaflet-labeled-icon {
  img,
  label {
    transform: scale(1.3);
    transform-origin: bottom;
  }
}
.leaflet-labeled-icon label {
  // @apply bg-yellow-400 text-yellow-900 border border-yellow-900 shadow-lg;
  @apply text-yellow-900 font-bold;
  font-size: 1.2em;
  padding: 0.5em 0.6em;
  margin-top: -45px;
  margin-left: -12px;
  cursor: pointer;
}

@screen sm {
  .leaflet-tooltip-pane {
    display: none;
  }
}
@screen lg {
  .leaflet-tooltip-pane {
    display: block;
  }
}
.small-caps {
  font-variant: small-caps;
}

.hide-resa .icon-resa {
  display: none;
}
.hide-linne .icon-linne {
  display: none;
}
.hide-plats .icon-plats {
  display: none;
}
.hide-person .icon-person {
  display: none;
}
.hide-verk .icon-verk {
  display: none;
}

.color-reddish {
  color: #e27155;
}
.padding-box {
  background-clip: padding-box;
}

.pulsating-circle {
  cursor: grab;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: #01a4e9;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-from,
.fade-enter-to {
  opacity: 1;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
.translate-z {
  transform: translateZ(0);
}

.search-results em {
  font-weight: bold;
  font-style: normal;
}
</style>
