<template>
  <div v-if="library">
    <img class="m-auto rounded-t-lg" :src="library.imgDataUrl" alt="" />
    <ul class="px-8 pt-8 pb-4">
      <li class="">
        <h2 class="text-2xl custom-sans uppercase font-black leading-tight mb-8">
          {{ library.name }}
        </h2>
      </li>
      <!-- <li v-html="library.address.replace(', ', '<br>')"></li> -->
      <!-- <li>{{ library.email }}</li> -->
      <!-- <li>{{ library.phone }}</li> -->
      <li class="paragraph" v-html="library.red_additions"></li>
    </ul>
    <div>
      <a
        v-if="$isDev"
        class="bg-indigo-400 cursor-pointer font-sans hover:text-indigo-600 inline-block mt-2 mt-4 p-2 rounded-lg font-bold text-sm text-indigo-900 uppercase ml-8 mr-2"
        @click="onEditClick(library)"
        >Redigera</a
      >
    </div>
    <ul
      class="text-md custom-sans text-gray-700 font-bold uppercase p-4 tracking-tight pb-8 sm:pb-4"
    >
      <li class="hover:text-red-800 flex items-center mb-1">
        <span class="w-12 flex-none">
          <font-awesome-icon
            class="text-lg mx-auto block text-gray-700 -mt-1"
            icon="map-marker-alt"
          />
        </span>
        <a :href="'https://www.google.com/maps/place/' + library.address" target="_blank"
          ><span v-if="library.address">{{ library.address }}</span>
        </a>
      </li>
      <li class="hover:text-red-800 flex items-center mb-1">
        <span class="w-12 flex-none">
          <font-awesome-icon class="text-lg mx-auto block text-gray-700 -mt-1" icon="envelope" />
        </span>
        <a
          :href="'mailto:' + library.email"
          :class="{
            'text-sm': library.email?.length > 30 && library.email?.length < 40,
            'text-xs tracking-tighter': library.email?.length > 40
          }"
          ><span> {{ library.email }}</span>
        </a>
      </li>
      <li class="hover:text-red-800 flex items-center mb-1" v-if="library.catalog">
        <span class="w-12 flex-none">
          <font-awesome-icon class="text-lg mx-auto block text-gray-700 -mt-1" icon="book" />
        </span>
        <a :href="library.catalog" target="_blank">Visa katalog </a>
      </li>
      <li class="hover:text-red-800 flex items-center mb-1">
        <span class="w-12 flex-none">
          <font-awesome-icon class="text-lg mx-auto block text-gray-700 -mt-1" icon="phone" />
        </span>
        <a :href="'tel:' + library.phone"
          ><span> {{ library.phone }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import * as backend from "../../backend"

const props = defineProps({
  library: Object
})

async function onEditClick(library) {
  await backend.modifyLastPage(library.id)
  let win = window.open("/login/admin/#/_/collections/library/" + library.id, "_blank")
  if (!win) return
  // window.win = win
  win.onload = function () {
    setTimeout(() => {
      win?.document?.querySelector("button[type=submit]")?.click()
    }, 500)
  }
}
</script>
<style lang="scss" scoped></style>
