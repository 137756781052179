<template>
  <div v-html="value"></div>
</template>

<script>
export default {
  computed: {
    value() {
      if (!this.highlight) return this.fallback
      // if partial string match gives more highlights, return it instead of full match.
      if (
        this.highlight[this.field + ".search"]?.[0].split("<em>").length >
        this.highlight[this.field]?.[0].split("<em>").length
      ) {
        return this.highlight[this.field + ".search"]?.[0]
      }
      return (
        this.highlight[this.field]?.[0] ||
        this.highlight[this.field + ".search"]?.[0] ||
        this.fallback
      )
    }
  },
  props: {
    highlight: {
      type: Object,
      default: null
    },
    field: {
      type: String
    },
    fallback: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped></style>
