import { library } from "@fortawesome/fontawesome-svg-core"
// import { fas } from "@fortawesome/free-solid-svg-icons"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { faBook } from "@fortawesome/free-solid-svg-icons"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { faCrosshairs } from "@fortawesome/free-solid-svg-icons"
import { faShare } from "@fortawesome/free-solid-svg-icons"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons"
// import { faQuestion } from "@fortawesome/free-solid-svg-icons"
import { faInfo } from "@fortawesome/free-solid-svg-icons"

// library.add(fas)
library.add(faMapMarkerAlt)
library.add(faUser)
library.add(faBook)
library.add(faSearch)
library.add(faShare)
library.add(faPlus)
// library.add(faQuestion)
library.add(faInfo)
library.add(faFacebook)
library.add(faFacebookF)
library.add(faChevronUp)
library.add(faSpinner)
library.add(faCrosshairs)
library.add(faLayerGroup)
library.add(faEnvelope)
library.add(faPhone)

// Vue.component("font-awesome-icon", FontAwesomeIcon)
