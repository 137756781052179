import { Machine, interpret, assign } from "xstate"
import { reactive } from "vue"
import { param, deparam } from "./util"
import EventBus from "./eventbus"

export const machine = Machine(
  {
    id: "main",
    context: {
      showSearchResults: false,
      showNearMe: false,
      placeId: null,
      libraryId: null,
      showAbout: false,
      searchVal: deparam().s || "",
      categories: null,
      showHelp: false,
      mapConstraints: {}
    },
    initial: "map",
    on: {
      MAP_CLICK: "map",
      SEARCH_INPUT_CLICK: "search.hist",
      SEARCH_INIT: { target: "search.filled", actions: "refreshSearch" },
      CATEGORY_CHANGE: { actions: "setCategories" },
      ABOUT: { target: "about_modal" },
      HELP: { target: "help" },
      MAP_CHANGED: { actions: "setMapContraints" }
    },
    states: {
      map: {
        on: {
          // SEARCH_RESULTS: "search",
          DETAILS: { target: "details", actions: ["setDetails", "maybeflyTo"] },
          keydown: [
            { target: "search", cond: "key_s" },
            { target: "", cond: "key_0", actions: "resetZoom" }
          ],
          SET_ADD_MARKER: { actions: ["addMarker"] }
        }
      },
      details: {
        on: {
          ESC: [{ target: "search.hist", cond: "isNotEmptyInput" }, { target: "map" }],
          DETAILS: { actions: ["setDetails", "flyTo"] },
          keydown: { target: "search.hist", cond: "key_s" }
        },
        exit: ["closeDetails"]
      },
      search: {
        on: {
          ESC: { target: "map" },
          DETAILS: {
            target: "details",
            actions: ["setDetails", "flyTo"],
            cond: "has_place"
          },
          ABOUT: "about_modal",
          MAP_CLICK: { target: "map", actions: ["clearSearchVal"] }
        },
        entry: ["focusSearch"],
        initial: "blank",
        states: {
          hist: {
            type: "history",
            target: "blank"
          },
          blank: {
            on: {
              NEAR_ME_RESULTS: { target: "results", actions: ["locate"] },
              SEARCH_INPUT: {
                target: "filled",
                cond: "isNotEmptyInput",
                actions: ["resetCategories"]
              }
            },
            entry: ["showNearMe", "focusSearch", "clearSearchVal"],
            exit: ["hideNearMe"]
          },
          filled: {
            on: {
              ESC: { target: "blank" },
              key_down: { target: "results", actions: ["refreshSearch"] },
              SEARCH_INPUT: { target: "blank", cond: "isEmptyInput" },
              SEARCH_RESULTS: "results"
            },
            entry: ["focusSearch"]
          },
          results: {
            on: {
              SEARCH_INPUT: { target: "blank", cond: "isEmptyInput" }
            },
            initial: "normal_results",
            states: {
              normal_results: {
                on: {
                  ESC: [
                    { target: "#main.search.blank", cond: "isEmptyInput" },
                    { target: "#main.search.filled", cond: "isNotEmptyInput" }
                  ],
                  SEARCH_NEAR: { target: "near_results", actions: ["flyTo"] }
                }
              },
              near_results: {
                on: {
                  ESC: {
                    target: "normal_results",
                    actions: ["refreshSearch", "focusSearch"]
                  },
                  SEARCH_NEAR: { actions: ["flyTo"] }
                }
              }
            },
            entry: ["openSearch", "focusSearch"],
            exit: ["closeSearch"]
          }
        }
        // entry: ["openSearch"],
        // exit: ["closeSearch"]
      },
      about_modal: {
        on: {
          ESC: { target: "map" },
          ABOUT: { target: "map" },
          DETAILS: { target: "details", actions: ["setDetails", "flyTo"] }
          // SHOW_HELP: { target: "map", actions: ["showHelp"] }
        },
        entry: ["openAbout"],
        exit: ["closeAbout"]
      },
      help: {
        on: {
          ESC: "map",
          HELP: "map"
        },
        entry: ["openHelp"],
        exit: ["closeHelp"]
      }
    }
  },
  {
    guards: {
      isEmptyInput(context, event) {
        return !context.searchVal
        // return !event.search_str
      },
      isNotEmptyInput(context, event) {
        return context.searchVal
      },
      has_place(context, event) {
        // don't crash on draft articles.
        return event.placeid != null
      },
      key_s(context, event) {
        return event.key == "s"
      },
      key_0(context, event) {
        return event.key == "0"
      },
      key_down(context, event) {
        return event.key == "ArrowDown"
      }
    },
    actions: {
      log: (context) => console.log("log context", context),
      setMapContraints(context, { center, distance }) {
        // distance refers to distance to the edge of the map viewport from center
        context.mapConstraints = { center, distance }
      },
      openSearch: assign({
        showSearchResults: true
      }),
      closeSearch: assign({
        showSearchResults: false
      }),
      showNearMe: assign({
        showNearMe: true
      }),
      hideNearMe: assign({
        showNearMe: false
      }),
      locate(context, event) {
        EventBus.$emit("locate", event)
      },
      refreshSearch(context, event) {
        EventBus.$emit("refreshSearch", event)
      },
      closeDetails: (context, event) => {
        context.placeId = null
        context.libraryId = null
        window.history.pushState(
          { ...deparam(), place: null },
          "",
          param({ visa: deparam().visa || null, id: null }, true)
        )
      },
      clearSearchVal: assign({
        searchVal: ""
      }),
      resetCategories(context, event) {
        EventBus.$emit("resetCategories", event)
      },
      setCategories: assign({
        categories: (context, event) => {
          console.log("🚀 ~ file: statemachine.js ~ line 205 ~ event", event.payload)
          const DEFAULT_CATEGORIES = "Verk,Plats,Person,Resa,Linnés resa"
          if (event.payload.length && event.payload.join(",") !== DEFAULT_CATEGORIES) {
            window.history.pushState(
              { place: null },
              "",
              window.location.pathname + param({ visa: event.payload.join(",") })
            )
          } else {
            let currentParam = param()
            let p = deparam()
            p.visa = null
            console.log("🚀 ~ file: statemachine.js ~ line 218 ~ p", p)
            window.history.pushState({ place: null, visa: null }, "", param(p))
          }
          return event.payload
        }
      }),
      openAbout: assign({
        showAbout: (context, event) => {
          window.history.pushState({ place: null }, "", "?om")
          return true
        }
      }),
      closeAbout: assign({
        showAbout: (context, event) => {
          window.history.pushState({ place: null }, "", ".")
          return false
        }
      }),
      openHelp: assign({
        showHelp: true
      }),
      closeHelp: assign({
        showHelp: false
      }),
      flyTo(context, event) {
        EventBus.$emit("flyTo", event)
      },
      resetZoom(context, event) {
        EventBus.$emit("resetZoom", event)
      },
      maybeflyTo(context, event) {
        // TODO bad logic
        if (event.isInit || (window.innerWidth > 600 && event.containerPoint?.x > 500)) {
          EventBus.$emit("flyTo", event)
        }
      },
      focusSearch() {
        EventBus.$emit("focusSearch")
      },
      addMarker(context, event) {
        EventBus.$emit("addMarker", event)
      },
      setDetails: (context, event) => {
        if (event.placeid) {
          context.libraryId = null
          let params = {
            id: event.placeid,
            article: null,
            library: null,
            visa: deparam().visa || null
          }
          if (event.articleid) {
            params["article"] = event.articleid
          }
          if (event.placeid == deparam().id) {
            window.history.replaceState({ place: event.placeid }, "", param(params))
          } else {
            window.history.pushState({ place: event.placeid }, "", param(params, true))
          }
          context.placeId = event.placeid
        } else {
          // library
          context.placeId = null
          window.history.pushState(
            { library: event.libraryid },
            "",
            param(
              { place: null, article: null, library: event.libraryid, visa: deparam().visa },
              true
            )
          )
          context.libraryId = event.libraryid
        }
      }
    }
  }
)

export const service = interpret(machine)

// Start the service
service.start()

// if (deparam().s) {
//   service.send("SEARCH_INIT")
// }

document.addEventListener("keydown", (event) => {
  if (["keyDown", "s", "n", "0"].includes(event.key)) {
    service.send(event)
  }
})
service.onTransition((state) => {
  console.log(
    "🚀 ~ state",
    state.event.type,
    state.configuration.map(({ id }) => id)
  )
})

// export function on(event, handler) {
//   EventBus.$on(event, handler)
// }

// export function send(event) {
//   service.send(event)
// }

// export default function() {
//   return useMachine(machine)
// }
// export default { send: service.send, on: (event, handler) => EventBus.$on(event, handler) }
