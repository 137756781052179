import { createApp, h, ref } from "vue"
import "./plugins/fontawesome"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
// import "./assets/tailwind.css"
import App from "./App.vue"
// import VueCompositionApi from "@vue/composition-api"
import { isDev } from "./util"
import { machine, service } from "./statemachine"
// Vue.use(VueCompositionApi)

window.gtag("config", window.gtagID, {
  send_page_view: false
})

let app = createApp({
  render: () => h(App)
})

// app.config.globalProperties.$isDev = isDev()
console.log(
  "🚀 ~ file: main.js ~ line 20 ~ app.config.globalProperties.$isDev",
  app.config.globalProperties.$isDev
)

app.component("font-awesome-icon", FontAwesomeIcon)

app.mixin({
  created() {
    this.$isDev = isDev()
    
    // Start service on component creation
    service.onTransition(state => {
      // console.log("state", this.name, state)
      // Update the current state component data property with the next state
      this.state = state
      // Update the context component data property with the updated context
      this.context = ref(state.context)
    })
  },
  data() {
    return {
      // Interpret the machine and store it in data
      service: service,

      // Start with the machine's initial state
      state: machine.initialState,

      // Start with the machine's initial context
      context: machine.context


    }
  },
  methods: {
    // Send events to the service
    send(event) {
      this.service.send(event)
    }
  }
})

app.mount("#app")
