<template>
  <div
    class="inline-block mb-8 bg-yellow-200 rounded-lg p-4 pb-2 pr-4 sm:mr-8 self-start flex-grow-0 flex-1 shadow-lg border border-yellow-300 bg-opacity-90"
  >
    <div class="flex-col">
      <div
        class="flex mr-4 items-center mb-2 cursor-pointer hover:text-yellow-700"
        tabindex="0"
        role="button"
        @click="toggleActive('Verk')"
        :class="{ 'opacity-25': !actives['Verk'] }"
      >
        <img class="w-4 object-contain mr-2" src="../assets/marker-icon-green.png" alt="" />
        <span class="uppercase font-sans font-bold">Verk</span>
      </div>
    </div>

    <div class="flex-col">
      <div
        class="flex mr-4 items-center mb-2 cursor-pointer hover:text-yellow-700"
        tabindex="0"
        role="button"
        @click="toggleActive('Plats')"
        :class="{ 'opacity-25': !actives['Plats'] }"
      >
        <img class="w-4 object-contain mr-2" src="~/leaflet/dist/images/marker-icon.png" alt="" />
        <span class="uppercase font-sans font-bold">Plats</span>
      </div>
      <div
        class="flex mr-4 items-center mb-2 cursor-pointer hover:text-yellow-700"
        tabindex="0"
        role="button"
        @click="toggleActive('Person')"
        :class="{ 'opacity-25': !actives['Person'] }"
      >
        <img class="w-4 object-contain mr-2" src="../assets/marker-icon-purple.png" alt="" />
        <span class="uppercase font-sans font-bold">Person</span>
      </div>

      <div
        class="flex mr-4 items-center mb-2 cursor-pointer hover:text-yellow-700"
        tabindex="0"
        role="button"
        @click="toggleActive('Resa')"
        :class="{ 'opacity-25': !actives['Resa'] }"
      >
        <img class="w-4 object-contain mr-2" src="../assets/marker-icon-white.png" alt="" />
        <span class="uppercase font-sans font-bold">Resor</span>
      </div>
      <div
        class="flex mr-4 items-center mb-2 cursor-pointer hover:text-yellow-700"
        tabindex="0"
        role="button"
        @click="toggleActive('Linnés resa')"
        :class="{ 'opacity-25': !actives['Linnés resa'] }"
      >
        <img class="w-4 object-contain mr-2" src="../assets/marker-icon-red.png" alt="" />
        <span class="uppercase font-sans font-bold whitespace-no-wrap">Linnés resa</span>
      </div>
      <div
        class="flex mr-4 items-center mb-2 cursor-pointer hover:text-yellow-700"
        tabindex="0"
        role="button"
        @click="toggleActive('library')"
        :class="{ 'opacity-25': !actives['library'] }"
      >
        <!-- 
         -->
        <img class="w-4 object-contain mr-2" src="../assets/library_marker.png" alt="" />
        <span class="uppercase font-sans font-bold whitespace-no-wrap">Bibliotek</span>
      </div>
    </div>
  </div>
</template>

<script>
import { deparam } from "../util"
import EventBus from "../eventbus"

let init = deparam().visa?.split(",") || []

export default {
  name: "CategoryPicker",

  data() {
    if (deparam().visa) {
      return {
        actives: {
          Verk: init.includes("Verk"),
          Plats: init.includes("Plats"),
          Person: init.includes("Person"),
          Resa: init.includes("Resa"),
          "Linnés resa": init.includes("Linnés resa"),
          library: init.includes("library")
        }
      }
    } else {
      return {
        actives: {
          Verk: true,
          Plats: true,
          Person: true,
          Resa: true,
          "Linnés resa": true,
          library: true
        }
      }
    }
  },
  created() {
    EventBus.$on("resetCategories", () => {
      this.reset()
    })
  },
  mounted() {
    if (deparam().visa) {
      this.send({
        type: "CATEGORY_CHANGE",
        payload: deparam().visa.split(",")
      })
    } else {
      // disable by default
      // this.actives["library"] = true
      this.toggleActive("library")
    }
  },
  methods: {
    reset() {
      for (let key in this.actives) {
        this.actives[key] = true
      }
      this.send({
        type: "CATEGORY_CHANGE",
        payload: Object.keys(this.actives)
      })
    },
    toggleActive(category) {
      console.log("🚀 ~ file: CategoryPicker.vue ~ line 121 ~ category", category)
      this.actives[category] = !this.actives[category]
      // window.document.body.classList.toggle("hide-" + category)
      this.send({
        type: "CATEGORY_CHANGE",
        payload: Object.entries(this.actives)
          .filter(([key, val]) => val)
          .map(([key]) => key)
      })
    }
  }
}
</script>

<style scoped>
:focus:not(:focus-visible) {
  outline: none;
}
</style>
