import axios from "axios"
import { isDev } from "./util"
import orderBy from "lodash/orderBy"
import groupBy from "lodash/groupBy"

// let DIRECTUS_BASE = "https://litteraturbanken.se/litteraturkartan/api/Directus/"
let DIRECTUS_BASE = "/litteraturkartan/api/Directus/"

// let LITTB_BASE = "http://localhost:5001/query"
let LITTB_BASE = "https://litteraturbanken.se/api/query"
if (window.location.hostname == "dev.litteraturbanken.se") {
  LITTB_BASE = "https://litteraturbanken.se/api/query"
} else if (window.location.hostname == "litteraturbanken.se") {
  LITTB_BASE = "https://litteraturbanken.se/api/query"
}

let directus_get = (path, params) =>
  axios
    .get(DIRECTUS_BASE + path, {
      headers: { accept: "*/*" },
      params
    })
    .then(({ data }) => data.data)
let allPlaces = null

async function getAuthorInfo(authorid) {
  let response = await axios.get("https://litteraturbanken.se/api/list_all/author", {
    params: {
      include: "full_name",
      filter_and: { authorid }
    }
  })

  return { ...response.data.data[0], authorid }
}

export async function getLibraries() {
  let response = await axios.get(DIRECTUS_BASE + `items/library`, {
    // TODO: filter status published
    params: {
      fields: "*"
    }
  })
  return response.data.data.map((obj) => ({ ...obj, _type: "library" }))
}
export async function getLibraryById(id) {
  let response = await axios.get(DIRECTUS_BASE + "items/library/" + id.toString(), {
    // TODO: filter status published
    params: {
      fields: "media_file.data.url,*"
    }
  })
  return response.data.data
}
async function getTravels() {
  let response = await axios.get(DIRECTUS_BASE + "items/travel", {
    // TODO: filter status published
    params: {
      // fields: "travelsort,travelid.name,id&filter[travelid][nnull]"
      fields: "articles.id,articles.travelsort,articles.placeid,*"
    }
  })

  // let articleTravelMap = groupBy(response.data, "id")
  let travelMap = {}
  for (let travel of response.data.data) {
    travel.articles = orderBy(travel.articles, (obj) => obj.travelsort || obj.id)
    for (let i = 0; i < travel.articles.length; i++) {
      let article = travel.articles[i]
      travelMap[article.id] = {
        isStart: i == 0,
        isEnd: i == travel.articles.length - 1,
        next: travel.articles[i + 1],
        prev: travel.articles[i - 1],
        start: travel.articles[0],
        travelName: travel.name
      }
    }
  }
  // getNextDestination(articleid) : articleid
  console.log("🚀 ~ file: backend.js ~ line 68 ~ travel", response.data.data)

  return travelMap
}

async function getWikidataInfo(article) {
  if (article.wikidata_person_id) {
    let response = await axios.get(
      `https://www.wikidata.org/wiki/Special:EntityData/${article.wikidata_person_id}.json`
    )
    let { claims, sitelinks } = response.data.entities[article.wikidata_person_id]
    const SBL = "P3217"
    const SKBL = "P4963"
    const SOL = "P5147"
    let output = {}
    if (SBL in claims) {
      output["sbl_url"] =
        "https://sok.riksarkivet.se/Sbl/Presentation.aspx?id=" +
        claims[SBL][0].mainsnak.datavalue.value
    }
    if (SKBL in claims) {
      let skbl_id = claims[SKBL][0].mainsnak.datavalue.value
      output["skbl_url"] = "https://skbl.se/sv/artikel/" + skbl_id
    }
    if (SOL in claims) {
      let sol_id = claims[SOL][0].mainsnak.datavalue.value
      output["sol_url"] = "https://litteraturbanken.se/översättarlexikon/artiklar/" + sol_id
    }
    return {
      ...output,
      wikipedia_url: sitelinks?.svwiki?.url
    }
  }

  return article
}

export async function getAllPlaces() {
  var filter = isDev() ? {} : { "filter[articles.status][eq]": "published" }
  // var filter = false ? {} : { "filter[articles.status][eq]": "published" }
  if (!allPlaces)
    allPlaces = directus_get("items/place", {
      // TODO: only status == published for production
      ...filter,
      limit: 10000,
      fields:
        "id,latlong,namn,status,articles.id,articles.header,articles.placeid,articles.status,articles.category"
    })

  let results = await allPlaces
  if (!isDev()) {
    for (let place of results) {
      place.articles = place.articles.filter((article) => article.status == "published")
    }
  }
  return results
}

export async function getPlace(pid) {
  let places = await allPlaces
  return places.find((place) => place.id == pid)
}

async function expandAuthorInfo(article) {
  if (article.authorid) {
    var authorInfo = await getAuthorInfo(article.authorid)
  }
  if (authorInfo) {
    article.authorInfo = authorInfo
  } else {
    article.authorInfo = {
      infoError: true
    }
  }
  return article
}

let travelPromise = getTravels()
export async function getAllByPlace(pid) {
  var filter = {}
  if (!isDev()) {
    filter = {
      "filter[status][eq]": "published"
    }
  }
  let articles = await directus_get("items/article2", {
    "filter[placeid][eq]": pid,
    fields: "*,media_file.data.url",
    ...filter
  })

  let getExternal = async (article) => {
    if (article.authorid) {
      await expandAuthorInfo(article)
    } else if (article.wikidata_person_id) {
      let props = await getWikidataInfo(article)
      article.authorInfo = props
    } else {
      article.authorInfo = {}
    }
    return article
  }
  articles = orderBy(articles, (item) => item.prio, "asc")
  let travelMap = await travelPromise
  for (let article of articles) {
    article.travel = travelMap[article.id]
  }
  return Promise.all(articles.map(getExternal))
}

// export async function search(str) {
//   let qs = str.split(" ")

//   function term_search(term) {
//     return directus_get("items/article", {
//       "filter[header][like]": term,
//       "filter[header][logical]": "or",
//       "filter[placeid.namn][like]": term,
//       "filter[placeid.namn][logical]": "or",
//       "filter[placeid.adress][like]": term,
//       "filter[placeid.adress][logical]": "or",
//       "filter[free_text][like]": term,
//       "filter[free_text][logical]": "or",
//       limit: 30,
//       fields: "id,placeid.*,header"
//     })
//   }
//   let articlesLists = await Promise.all(qs.map(term_search))
//   let articles = intersectionBy(...articlesLists, "id")

//   return articles
// }

export async function lbSearch(str, { center, distance }, getAll = false) {
  // let token = await auth_promise
  // let qs = str.split(" ")
  var filter = {}
  if (!isDev()) {
    filter = {
      filter: {
        term: {
          status: "published"
        }
      }
    }
  }
  let resp = await axios.get(LITTB_BASE + "/litteraturkartan,litteraturkartan_library", {
    params: {
      to: getAll ? 1000 : 15,
      search: JSON.stringify({
        query: {
          bool: {
            ...filter,
            must: [
              {
                query_string: {
                  query: "_exists_:category " + str,
                  fields: [
                    "author",
                    "author.search",
                    "article_author",
                    "article_author.search",
                    "header^1.8",
                    "placeaddress^1.5",
                    "placename^1.5",
                    "free_text",
                    "author.search",
                    "header.search",
                    "placeaddress.search",
                    "placename.search",
                    "free_text.search",
                    "lb_author.full_name^10",
                    "lb_author.full_name.search",

                    // library
                    "name",
                    "name.search"
                  ],
                  default_operator: "and",
                  type: "cross_fields"
                }
              }
            ],
            should: [
              {
                distance_feature: {
                  field: "placecoord",
                  pivot: (distance / 2).toString() + "m",
                  origin: [center.lng, center.lat],
                  boost: 10
                }
              }
            ]
          }
        },
        highlight: {
          order: "score",
          number_of_fragments: 3,
          boundary_chars: ".,!? \t\n<>;",
          fragment_size: 100,
          fields: {
            "*": {}
          }
        }
      })
    }
  })
  return { data: resp.data.data, hits: resp.data.hits }
}

let counties = [14, 10, 13, 17]

// if (isDev()) {
//   counties = [14, 10, 13, 17]
// }

export async function searchGeonames(str) {
  let resp = await axios.get(LITTB_BASE + "/geonames", {
    params: {
      search: JSON.stringify({
        query: {
          query_string: {
            fields: ["name.search^1.1", "name^1.3", "municipality", "municipality.search"],
            query: `${str} AND county_code:(${counties.join(" OR ")})`
          }
        },
        highlight: { fields: { "*": {} } }
      })
    }
  })
  let aggs = {}
  for (let item of resp.data.data) {
    item.nearHits = 0
    aggs[item.id] = {
      geo_distance: {
        field: "placecoord",
        origin: {
          lat: parseFloat(item.coord.lat),
          lon: parseFloat(item.coord.lon)
        },
        unit: "km",
        ranges: [
          {
            from: 0,
            to: 7
          }
        ]
      }
    }
  }
  let aggsData = await countNear(aggs)
  for (let item of resp.data.data) {
    item.nearHits = aggsData[Number(item.id)].buckets[0].doc_count
  }
  return resp.data.data
}

async function countNear(aggs) {
  var pubFilter = []
  if (!isDev()) {
    var pubFilter = {
      query: {
        bool: {
          filter: {
            term: {
              status: "published"
            }
          }
        }
      }
    }
  }
  let resp = await axios.get(LITTB_BASE + "/litteraturkartan", {
    params: {
      to: 0,
      search: JSON.stringify({
        ...pubFilter,
        aggs
      })
    }
  })
  return resp.data.aggs
}

export async function searchNear(coord) {
  var pubFilter = []
  if (!isDev()) {
    var pubFilter = [
      {
        term: {
          status: "published"
        }
      }
    ]
  }

  let resp = await axios.get(LITTB_BASE + "/litteraturkartan", {
    params: {
      to: 100,
      search: JSON.stringify({
        query: {
          bool: {
            filter: {
              bool: {
                must: [
                  {
                    geo_distance: {
                      distance: "7km",
                      placecoord: coord
                    }
                  },
                  ...pubFilter
                ]
              }
            }
          }
        }
      })
    }
  })
  return resp.data.data
}

export async function postPlace(params) {
  let places = await allPlaces
  if (
    places.some(
      (place) =>
        place.latlong.lat == params.latlong.lat.toString() &&
        place.latlong.lng == params.latlong.lng.toString()
    )
  ) {
    throw "Duplicate place."
  }
  let resp = await axios.post(DIRECTUS_BASE + "auth/authenticate", {
    email: "frontend@example.com",
    password: "password"
  })
  let token = resp.data.data.token
  let response = await axios({
    url: DIRECTUS_BASE + "items/place",
    method: "POST",
    data: params,
    headers: { Authorization: "Bearer " + token }
  })
  getAllPlaces()
  return response.data
}

export async function modifyLastPage(id) {
  let resp = await axios.post(DIRECTUS_BASE + "auth/authenticate", {
    email: "frontend@example.com",
    password: "password"
  })
  let token = resp.data.data.token
  let userIds = [1, 2]
  function mod(userid) {
    return axios({
      url: DIRECTUS_BASE + "users/" + userid,
      method: "PATCH",
      data: {
        last_page: "/_/collections/article2/" + id
      },
      headers: { Authorization: "Bearer " + token }
    })
  }

  return Promise.all(userIds.map(mod))
}
